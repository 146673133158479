import { BackgroundColors, Color, DefaultTheme, TextColors } from 'styled-components';

const primaryColors: Color = {
  100: '#f2ecdd',
  200: '#e6d9bb',
  300: '#dac69a',
  400: '#ceb378',
  500: '#C2A157', // Exact
  600: '#a4843b',
  700: '#7b632c',
  800: '#52421d',
  900: '#29210e',
};

const secondaryColors: Color = {
  100: '#d1e8ef',
  200: '#a3d2e0',
  300: '#75bbd0',
  400: '#47a5c1',
  500: '#338198', // Exact
  600: '#286779',
  700: '#19404c',
  800: '#0f262d',
  900: '#0a191e',
};

const tertiaryColors: Color = {
  100: '#d8a500',
  200: '#a77f00',
  300: '#765a00',
  400: '#453500',
  500: '#151000', // Exact
  600: '#100c00',
  700: '#0c0900',
  800: '#080600',
  900: '#040300',
};

const backgroundColors: BackgroundColors = {
  default: '#F8F8F8',
  light: '#FFFFFF',
  lightPlus: '#f5f0e5',
};

const textColors: TextColors = {
  dark: tertiaryColors[900],
  light: '#fff',
};

const theme: DefaultTheme = {
  colors: {
    primary: primaryColors,
    secondary: secondaryColors,
    tertiary: tertiaryColors,
    background: backgroundColors,
    text: textColors,
    alert: '#f8c4b9',
    error: '#fff',
    white: '#fff',
  },
  font: {
    family: "'Montserrat', sans-serif",
    weights: {
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    sizes: {
      h1: {
        desktop: '30px',
        mobile: '26px',
      },
      h2: {
        desktop: '28px',
        mobile: '22px',
      },
      h3: {
        desktop: '20px',
        mobile: '20px',
      },
      h4: {
        desktop: '17px',
        mobile: '15px',
      },
      h5: {
        desktop: '14px',
        mobile: '14px',
      },
      quote: {
        desktop: '30px',
        mobile: '25px',
      },
      title: {
        desktop: '21px',
        mobile: '20px',
      },
      intro: {
        desktop: '19px',
        mobile: '19px',
      },
      standard: {
        desktop: '16px',
        mobile: '16px',
      },
      medium: {
        desktop: '14px',
        mobile: '14px',
      },
      small: {
        desktop: '13px',
        mobile: '13px',
      },
      nav: {
        desktop: '12px',
        mobile: '12px',
      },
    },
  },
  breakpoints: {
    large: 1280,
    desktop: 1080,
    tablet: 950,
    mobile: 600,
  },
  sizes: {
    content: 1360,
  },
};

export default theme;
