import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import logo from '@assets/logos/logo.svg';
import { navEntries } from '@layout/nav/model';
import { useAuthContext } from '@modules/auth/context';
import { Profile } from '@modules/auth/model';
import { renderNullable } from '@shared/utils/render';
import { sequenceT } from 'fp-ts/Apply';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import * as Styled from './Nav.styles';

function getNullableName(firstName: string | null, lastName: string | null, fallback: string): string {
  return pipe(
    sequenceT(O.Apply)(O.fromNullable(firstName), O.fromNullable(lastName)),
    O.fold(
      () => fallback,
      ([firstName, lastName]) => `${firstName} ${lastName}`,
    ),
  );
}

function getProfileName(profile: Profile): string {
  return getNullableName(profile.firstName, profile.lastName, 'Admin');
}

interface NavProps {
  profile: Profile;
}

const Nav: FC<NavProps> = ({ profile }) => {
  const location = useLocation();

  const { logout } = useAuthContext();

  const navRef = useRef<HTMLDivElement>(null);
  const burgerRef = useRef<HTMLDivElement>(null);

  const [navOpen, setNavOpen] = useState<boolean>(false);

  const toggleNav = () => setNavOpen(old => !old);

  const entries = useMemo(() => navEntries, []);

  useEffect(() => {
    setNavOpen(false);
  }, [location.pathname]);

  return (
    <>
      <Styled.NavBurger ref={burgerRef} isOpen={navOpen} onClick={toggleNav}>
        <span />
      </Styled.NavBurger>
      <Styled.NavContainer ref={navRef} isOpen={navOpen}>
        <Styled.NavHeader>
          <Link to="/">
            <img src={logo} alt="logo" width="50px" />
          </Link>
        </Styled.NavHeader>
        <Styled.NavContent>
          <Styled.NavEntries>
            {entries?.map((entry, j) => (
              <div key={j}>
                {renderNullable(
                  entry.to,
                  to => (
                    <li>
                      <Styled.NavLinkContainer to={to} activeClassName="active">
                        <div>
                          {entry.icon && <Icon name={entry.icon} />}
                          <span>{entry.title}</span>
                          {entry.count && profile.initialStateOrderCount > 0 && (
                            <Styled.NavLinkTextCount>{profile.initialStateOrderCount}</Styled.NavLinkTextCount>
                          )}
                        </div>
                      </Styled.NavLinkContainer>
                    </li>
                  ),
                  () => (
                    <>
                      <Styled.NavGroupHeader>
                        <h5>{entry.title}</h5>
                      </Styled.NavGroupHeader>
                      {entry.entries?.map((entry, k) => (
                        <li key={k}>
                          <NavLink to={entry.to} activeClassName="active" key={k}>
                            <div>
                              {entry.icon && <Icon name={entry.icon} />}
                              <span>{entry.title}</span>
                            </div>
                          </NavLink>
                        </li>
                      ))}
                    </>
                  ),
                )}
              </div>
            ))}
          </Styled.NavEntries>
        </Styled.NavContent>
        <Styled.NavFooter>
          <Link to={`/profile`}>
            <p>{getProfileName(profile)}</p>
            <p>Administrateur</p>
          </Link>
          <Styled.LogoutButton onClick={logout}>
            <Icon name="sign-out" />
          </Styled.LogoutButton>
        </Styled.NavFooter>
      </Styled.NavContainer>
    </>
  );
};

export default Nav;
