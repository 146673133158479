import { SemanticICONS } from 'semantic-ui-react';

interface NavEntryParent {
  title: string;
  to?: string;
  icon?: SemanticICONS;
  entries?: Array<NavEntryChildren>;
  count?: boolean;
}

interface NavEntryChildren extends NavEntryParent {
  to: string;
}

export const navEntries: Array<NavEntryParent> = [
  { title: 'Produits', to: '/products' },
  { title: 'Photographes', to: '/photographs' },
  { title: 'Commandes', to: '/orders', count: true },
  // {
  //   title: 'OFFRES SPECIALES',
  //   entries:[
  //     { title: 'Promotions', to: '/specialoffer/promotions' },
  //     { title: 'Code Promo', to: '/specialoffer/promocode' },
  //   ]
  // },
  {
    title: 'Référentiels',
    entries: [
      { title: 'Papiers', to: '/referentials/papers' },
      { title: 'Options simples', to: '/referentials/basic-options' },
      { title: 'Formats', to: '/referentials/formats' },
      { title: 'Encadrements', to: '/referentials/framings' },
      { title: 'Collection Open & Frame', to: '/referentials/of' },
      { title: 'Collection Nature Fine Art', to: '/referentials/nfa' },
      { title: 'Univers', to: '/referentials/universes' },
      { title: 'Ambiances', to: '/referentials/atmospheres' },
      { title: 'Thèmes', to: '/referentials/themes' },
      { title: 'Couleurs', to: '/referentials/colors' },
      { title: 'Sélection', to: '/referentials/selections' },
      { title: 'En-tête personnalisé "Toutes les photos"', to: '/referentials/header' },
      { title: 'État des commandes', to: '/referentials/order-statuses' },
    ],
  },
  {
    title: 'Administration',
    entries: [
      { title: 'Livraison', to: '/delivery' },
      { title: 'Gestion des utilisateurs BO', to: '/users' },
    ],
  },
];
