import React, { FC, Suspense } from 'react';
import { Route as ReactRoute, Switch } from 'react-router-dom';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import ErrorPage from '@shared/components/error-page/ErrorPage';

export const Routes: FC = ({ children }) => (
  <Suspense fallback={<DebouncedLineLoader />}>
    <Switch>
      {children}
      <ReactRoute component={ErrorPage} />
    </Switch>
  </Suspense>
);
