import styled from 'styled-components';
import { mediaMax } from '@styles/utils';
import { TableHeader as VirtualizedTableHeader } from '@shared/components/table/Table.styles';

export { VirtualizedTableHeader };

export const VirtualizedTableRow = styled.div`
  display: grid;
  height: 100%;
  align-items: center;
  padding: 10px 15px;

  p {
    margin: 0;
  }

  ${mediaMax.tablet`
    padding: 10px;
  `};
`;
