import { backgroundColor, mediaMax, primaryColor } from '@styles/utils';
import { Tab } from 'semantic-ui-react';
import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

export const PageContentWrapper = styled.div`
  flex: 1 1 auto;
  width: 100%;
  overflow-y: auto;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
`;

export const PageTopBar = styled.div`
  display: flex;
  align-items: center;
  min-height: 71px;
  padding: 0 30px;
  background: #fff;
  border-bottom: 1px solid #ddd;

  .ui.breadcrumb {
    a.section {
      color: ${primaryColor()};
    }

    .section {
      font-weight: 600;
    }
  }

  ${mediaMax.large`
    padding-left: 65px;
  `};
`;

export const PageTopBarLeft = styled.div`
  flex: 1 1 auto;

  .breadcrumb .section {
    display: inline-flex;
    align-items: center;
  }
`;

export const PageToBarRight = styled.div`
  flex: 1 1 auto;
  text-align: right;
`;

export const PageTabs = styled(Tab)`
  .ui.pointing.secondary.menu {
    background: ${props => props.theme.colors.white};

    > a.item {
      padding: 16px 25px;
      font-weight: 500;

      &.active {
        border-color: ${primaryColor()};
      }
    }
  }
`;

export const PageChildren = styled.div`
  flex: 1 1 auto;
  padding-bottom: 20px;
`;

export const PageBottomBar = styled.div`
  min-height: 60px;
  background: ${backgroundColor.light};
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.04);

  > div {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0 15px;

    ${mediaMax.tablet`
    padding: 0 5px;
  `};

    .button {
      margin-right: 7px !important;
    }
  }
`;

export const PageBottomBarLeft = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 0;
  ${mediaMax.mobile`
    > button, a {
      width: 100%;
    }
  `}
`;

export const PageBottomBarRight = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-end;
  padding: 7px 0;
  ${mediaMax.mobile`
    > button, a {
      width: 100%;
    }
  `}
`;
