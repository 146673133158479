import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html, body {
    height: 100%;
    overflow: hidden;
  }

  #root {
    height: 100%;
    overflow: hidden;
  }

  .disabled{
    & > input {
      pointer-events: auto !important;
    }
  }
`;
