import { mediaMax } from '@styles/utils';
import styled from 'styled-components';

export const LayoutContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;

  ${mediaMax.desktop`
      display: none;
  `};
`;

export const LayoutContent = styled.div`
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
`;

export const LockScreen = styled.div`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0.6;
  background-size: cover;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 10px;
    row-gap: 15px;
    max-width: 650px;
    font-size: 18px;
  }

  ${mediaMax.desktop`
      display: flex;
  `};
`;
