import Nav from '@layout/nav/Nav';
import { Profile } from '@modules/auth/model';
import React, { FC } from 'react';
import { Header as HeaderSemantic, Icon } from 'semantic-ui-react';
import * as Styled from './Layout.styles';

interface LayoutProps {
  profile: Profile;
}

const Layout: FC<LayoutProps> = ({ profile, children }) => {
  return (
    <>
      <Styled.LockScreen>
        <div>
          <Icon name="exclamation triangle" size="huge" color="red" />
          <HeaderSemantic>On dirait que vous êtes sur un appareil mobile.</HeaderSemantic>
          <p>
            Pour une meilleure expérience utilisateur, nous vous invitons à vous connecter depuis un ordinateur et à
            activer le mode « plein écran » de votre navigateur.
          </p>
        </div>
      </Styled.LockScreen>
      <Styled.LayoutContainer>
        <Nav profile={profile} />
        <Styled.LayoutContent>{children}</Styled.LayoutContent>
      </Styled.LayoutContainer>
    </>
  );
};

export default Layout;
