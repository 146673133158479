import { ComponentType, lazy } from 'react';

export function safeLazy<T extends ComponentType<any>>(factory: () => Promise<{ default: T }>) {
  return lazy<T>(() =>
    factory().catch(err => {
      window.location.reload();
      return err;
    }),
  );
}
