import { Routes, safeLazy } from '@core/router';
import { Profile } from '@modules/auth/model';
import React, { FC } from 'react';
import { Route } from 'react-router-dom';

const ProductsRoutes = safeLazy(() => import('@modules/app/products/routes'));
const PhotographsRoutes = safeLazy(() => import('@modules/app/photographs/routes'));
const UserRoutes = safeLazy(() => import('@modules/app/admin/users/routes'));
const ReferentialsRoutes = safeLazy(() => import('@modules/app/referentials/routes'));
const DeliveryRoutes = safeLazy(() => import('@modules/app/admin/delivery/routes'));
const OrdersRoutes = safeLazy(() => import('@modules/app/orders/routes'));
// const SpecialOfferRoutes = safeLazy(()=> import('@modules/app/specialoffer/routes'));

interface AppRoutesProps {
  profile: Profile;
}

const AppRoutes: FC<AppRoutesProps> = ({ profile }) => (
  <Routes>
    <Route path="/products" component={ProductsRoutes} />
    <Route path="/photographs" component={PhotographsRoutes} />
    <Route path="/orders" component={OrdersRoutes} />
    <Route path="/users" component={UserRoutes} />
    {/* <Route path="/specialOffer" component={SpecialOfferRoutes} /> */}
    <Route path="/referentials" component={ReferentialsRoutes} />
    <Route path="/delivery" component={DeliveryRoutes} />
  </Routes>
);

export default AppRoutes;
