import { css, DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';

import theme from '../theme';
import { mediaMax } from './responsive';

export const fontFamily = (props: ThemeProps<DefaultTheme>) => props.theme.font.family;

export const fontWeight = (Object.keys(theme.font.weights) as (keyof typeof theme.font.weights)[]).reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: (props: ThemeProps<DefaultTheme>) => props.theme.font.weights[curr],
  }),
  {} as { [key in keyof typeof theme.font.weights]: (props: ThemeProps<DefaultTheme>) => number },
);

export const fontSize = (Object.keys(theme.font.sizes) as (keyof typeof theme.font.sizes)[]).reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: css`
      ${props => {
        const size = props.theme.font.sizes[curr];

        return css`
          font-size: ${size.desktop};

          ${size.desktop !== size.mobile &&
          mediaMax.tablet`
              font-size: ${size.mobile};
            `};
        `;
      }};
    `,
  }),
  {} as { [key in keyof typeof theme.font.sizes]: FlattenInterpolation<ThemeProps<DefaultTheme>> },
);
