import { rgba as polishedRgba } from 'polished';
import { DefaultTheme, PrimaryColor, SecondaryColor, TertiaryColor, ThemeProps } from 'styled-components';

import theme from '../theme';

function getColor<C extends Record<string, string>>(
  color: C,
  defaultKey: Extract<keyof C, number>,
  key?: Extract<keyof C, number>,
): string {
  return color[key ?? defaultKey];
}

export function primaryColor(key?: Extract<keyof PrimaryColor, number>): (props: ThemeProps<DefaultTheme>) => string {
  return ({ theme }) => getColor(theme.colors.primary, 500, key);
}

export function secondaryColor(
  key?: Extract<keyof SecondaryColor, number>,
): (props: ThemeProps<DefaultTheme>) => string {
  return ({ theme }) => getColor(theme.colors.secondary, 500, key);
}

export function tertiaryColor(key?: Extract<keyof TertiaryColor, number>): (props: ThemeProps<DefaultTheme>) => string {
  return ({ theme }) => getColor(theme.colors.tertiary, 700, key);
}

export const backgroundColor = (
  Object.keys(theme.colors.background) as (keyof typeof theme.colors.background)[]
).reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: (props: ThemeProps<DefaultTheme>) => props.theme.colors.background[curr],
  }),
  {} as { [key in keyof typeof theme.colors.background]: (props: ThemeProps<DefaultTheme>) => string },
);

export const textColor = (Object.keys(theme.colors.text) as (keyof typeof theme.colors.text)[]).reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: (props: ThemeProps<DefaultTheme>) => props.theme.colors.text[curr],
  }),
  {} as { [key in keyof typeof theme.colors.text]: (props: ThemeProps<DefaultTheme>) => string },
);

export function alertColor(props: ThemeProps<DefaultTheme>): string {
  return props.theme.colors.alert;
}

export function whiteColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.white;
}

export function errorColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.error;
}

export function rgba(
  colorFn: (props: ThemeProps<DefaultTheme>) => string,
  amount: number,
): (props: ThemeProps<DefaultTheme>) => string {
  return props => polishedRgba(colorFn(props), amount);
}
