import { createBrowserHistory } from 'history';
import React, { FC } from 'react';
import { Router } from 'react-router-dom';
import RootRoutes from 'routes';
import { ThemeProvider } from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';

import { AuthContextProvider } from '@modules/auth/context';
import ToastsProvider from '@shared/components/toasts/ToastsProvider';
import { GlobalStyles } from '@styles/global';
import theme from '@styles/theme';

export const history = createBrowserHistory();

const App: FC = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <ToastsProvider />

    <HelmetProvider>
      <Router history={history}>
        <AuthContextProvider>
          <RootRoutes />
        </AuthContextProvider>
      </Router>
    </HelmetProvider>
  </ThemeProvider>
);

export default App;
